import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo'


const EventsPage = () => {

    const data = useStaticQuery(graphql`
		query EventsPage {
            allWpEvent (
                sort: {order: ASC, fields: eventMeta___eventStartDate}
                filter: {eventMeta: {eventHasPassed: {ne: true}}}
            ) {
				nodes {
                    title
                    uri
                    date
                    eventMeta {
                        eventDateDisplay
                        eventLocation
                        eventStartDate
                    }
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
			}
        }
	`);

    const imageData = getImage(data?.allWpEvent?.nodes[0]?.featuredImage?.node?.localFile);

    return (
        <Layout>
            <Seo title="Events" />

            <h1 className="text-center py-16">Events</h1>
            <div className="container mx-auto grid px-4 md:px-0 lg:px-24 md:grid-cols-2 xl:px-0 xl:grid-cols-4 gap-8">
                {data.allWpEvent.nodes.map((event, i) => {

                    const displayDay = new Date(event.eventMeta.eventStartDate);
                    const imageData  = getImage(event.featuredImage?.node?.localFile);

                    return (
                        <Link
                            to={event.uri}
                            className="upcoming-events pt-6 pb-8 block"
                            index={i}
                            >
                            {imageData ?
                                <GatsbyImage image={imageData} className="rounded-lg w-full" alt={event.title} width="260" />
                                :
                                <StaticImage src="../../assets/images/fallbacks/Seminar.jpg" className="rounded-lg" alt={event.title} width="260" />

                            }                            

                            <div className="flex items-center mt-8">
                                <div className="bg-gold-100 rounded-2xl w-32 h-24 flex justify-center items-center flex-col mr-8">
                                    <span className="text-3xl text-gold-700">{ new Intl.DateTimeFormat( 'en-US', { day: 'numeric' }).format(displayDay) }</span>
                                    <span className="text-gray-600">{ new Intl.DateTimeFormat( 'en-US', { month: 'short' }).format(displayDay) }</span>
                                </div>
                                <div className="w-full">
                                    <h3 className="mb-4 pt-2 mt-0">{event.title}</h3>
                                    <span className="text-gray-600">{event.eventMeta.eventLocation}</span>
                                </div>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </Layout>
    )
}

export default EventsPage